<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <p class="title-name text-center">Falso - Verdadero</p>
        <div
          class="content-question"
          v-for="(question, index) in questions"
          :key="question"
        >
          <div
            class="text-center main-text"
            v-if="question.tipo_pregunta == 'falso, verdadero'"
          >
            <p class="main-text">
              <span class="index">{{ index }}.-</span> {{ question.pregunta }}
              <span @click="delete_question(index)"
                ><i class="delete-icon fas fa-trash-alt"></i
              ></span>
            </p>
            <div v-for="resp in question.respuestas" :key="resp">
              <p class="resp-text" v-if="resp.estado == true">
                Respuesta: {{ resp.respuesta }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <p class="title-name text-center">Pregunta - Respuesta</p>
        <div
          class="content-question"
          v-for="(question, index) in questions"
          :key="question"
        >
          <div
            class="text-center main-text"
            v-if="question.tipo_pregunta != 'falso, verdadero'"
          >
            <p class="main-text">
              <span class="index">{{ index }}.-</span> {{ question.pregunta }}
              <span @click="delete_question(index)"
                ><i class="delete-icon fas fa-trash-alt"></i
              ></span>
            </p>
            <div v-for="resp in question.respuestas" :key="resp">
              <p class="resp-text">
                Respuesta: {{ resp.respuesta }}
                <span v-if="resp.estado == true"
                  ><i class="check-icon fas fa-check"></i
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ViewAllQuestions",
  props: ["id"],
  data() {
    return {
      questions: null,
      url: process.env.VUE_APP_URL
    };
  },
  methods: {
    delete_question(index) {
      this.$swal({
        icon: "warning",
        title: "Borrar Pregunta!",
        text:
          "¿ Está seguro de BORRAR esta pregunta y todas las sus respuestas ?",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#7AB929",
        cancelButtonColor: "#CB3234",
        focusCancel: true,
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(
              this.url + "/api/preguntas/" + this.questions[index]._id.$oid,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
                }
              }
            )
            .then(response => {
              // console.log(response);
              this.questions.splice(index, 1);
            })
            .catch(e => {
              console.log(e);
            });
        }
      });
    }
  },
  async mounted() {
    let questionResponse = await axios.get(
      this.url + `/api/preguntas?curso_id=${this.id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.questions = questionResponse.data.preguntas;
    // console.log(this.questions[0]._id.$oid)
  }
};
</script>
<style scoped>
.main-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 30px; */
  font-size: 3vmin;
}
.title-name {
  font-family: "Fredoka One", cursive;
  color: #1d2945;
  /* font-size: 2rem; */
  font-size: 3vmin;
  font-weight: bold;
}
.resp-text {
  font-family: "Poiret One", cursive !important;
  color: #1d2945;
  /* font-size: 22px; */
  font-size: 3vmin;
}
.check-icon {
  color: green;
  /* font-size: 20px; */
  font-size: 3vmin;
}
.delete-icon {
  /* margin: 2px; */
  color: #ffffff;
  cursor: pointer;
  /* font-size: 1.8rem; */
  font-size: 3.8vmin;
  background-color: #cc0000;
  border-radius: 5px;
  /* padding: 4px; */
  padding: 0.5vmin;
}
.index {
  color: #1d2945;
  font-family: "Fredoka One", cursive;
}
.content-question {
  margin-top: 5px;
  border-radius: 15px;
  background-color: #d7dade;
}

</style>

