<template>
  <div class="main" v-if="lesson">
    <NavMain />

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="pos">
            <div class="col-2 my-5" v-if="is_portrait == false && is_landscape">
              <div class="doll" v-if="is_portrait == false">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="40vmin"
                  viewBox="0 0 350 300"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <line
                    v-if="strikes > 0"
                    x1="80"
                    y1="300"
                    x2="360"
                    y2="300"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 1"
                    x1="100"
                    y1="300"
                    x2="100"
                    y2="0"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 2"
                    x1="100"
                    y1="0"
                    x2="290"
                    y2="0"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 3"
                    x1="100"
                    y1="80"
                    x2="200"
                    y2="0"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 4"
                    x1="290"
                    y1="0"
                    x2="290"
                    y2="60"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <circle
                    v-if="strikes > 5"
                    cx="290"
                    cy="90"
                    style="fill:#7AB929;stroke:black;stroke-width:2px;"
                    r="30"
                  />
                  <line
                    v-if="strikes > 6"
                    x1="290"
                    y1="120"
                    x2="290"
                    y2="210"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 7"
                    x1="290"
                    y1="170"
                    x2="350"
                    y2="120"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 8"
                    x1="290"
                    y1="170"
                    x2="230"
                    y2="120"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 9"
                    x1="290"
                    y1="210"
                    x2="350"
                    y2="260"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                  <line
                    v-if="strikes > 10"
                    x1="290"
                    y1="210"
                    x2="240"
                    y2="260"
                    style="stroke:black;fill:none;stroke-width:2px;"
                  />
                </svg>
              </div>
            </div>
            <div class="col-6">
              <div class="main-game">
                <div class="text-center definition-title">
                  Definición:
                  <p class="text-definition">{{ block_aleatory.definicion }}</p>
                  <!-- doll portrait -->
                </div>
                <div class="doll-portrait" v-if="is_portrait && is_landscape == false">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="40vmin"
                    viewBox="30 0 450 300"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <line
                      v-if="strikes > 0"
                      x1="80"
                      y1="300"
                      x2="360"
                      y2="300"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 1"
                      x1="100"
                      y1="300"
                      x2="100"
                      y2="0"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 2"
                      x1="100"
                      y1="0"
                      x2="290"
                      y2="0"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 3"
                      x1="100"
                      y1="80"
                      x2="200"
                      y2="0"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 4"
                      x1="290"
                      y1="0"
                      x2="290"
                      y2="60"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <circle
                      v-if="strikes > 5"
                      cx="290"
                      cy="90"
                      style="fill:#7AB929;stroke:black;stroke-width:2px;"
                      r="30"
                    />
                    <line
                      v-if="strikes > 6"
                      x1="290"
                      y1="120"
                      x2="290"
                      y2="210"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 7"
                      x1="290"
                      y1="170"
                      x2="350"
                      y2="120"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 8"
                      x1="290"
                      y1="170"
                      x2="230"
                      y2="120"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 9"
                      x1="290"
                      y1="210"
                      x2="350"
                      y2="260"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                    <line
                      v-if="strikes > 10"
                      x1="290"
                      y1="210"
                      x2="240"
                      y2="260"
                      style="stroke:black;fill:none;stroke-width:2px;"
                    />
                  </svg>
                </div>
                <!-- Game -->
                <div class="content-letter">
                  <!-- ESTE FOR NO DEBE TENER :KEY PUES SE BUGEA LA VISTA DE LAS LETRAS   -->
                  <!--  -->
                  <div
                    class="letter"
                    :class="guion(letter)"
                    v-for="letter in wordDisplayLetters"
                  >
                    {{ letter }}
                  </div>
                </div>
                <template v-if="initialized">
                  <div class="content-keyword">
                    <div
                      @click="tryLetter(letter)"
                      class="possibleLetter"
                      :class="getStrikethroughClass(letter)"
                      v-for="letter in possibleLetters1"
                      :key="letter"
                    >
                      {{ letter }}
                    </div>
                  </div>
                  <div class="content-keyword">
                    <div
                      @click="tryLetter(letter)"
                      class="possibleLetter"
                      :class="getStrikethroughClass(letter)"
                      v-for="letter in possibleLetters2"
                      :key="letter"
                    >
                      {{ letter }}
                    </div>
                  </div>
                  <div class="content-keyword">
                    <div
                      @click="tryLetter(letter)"
                      class="possibleLetter"
                      :class="getStrikethroughClass(letter)"
                      v-for="letter in possibleLetters3"
                      :key="letter"
                    >
                      {{ letter }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-3 position-fixed end-0">
              <div class="side-bar">
                <p class="title-menu">{{ game_title }}</p>
                <!-- <p class="line"></p> -->

                <div class="content-side-bar">
                  <!-- <p class="title-menu">Cuestionario</p> -->
                  <!-- <p class="line"></p> -->
                  <div class="justify-content-center">
                    <div class="subtitle">Reglas:</div>
                    <hr class="menu-line" />
                    <p class="text-side-bar">
                      {{ game_rules }}
                    </p>
                  </div>
                </div>
                <button
                  v-if="start == true"
                  @click="initialize()"
                  class="btn-reset"
                  type="button"
                >
                  <i class="fas fa-play"></i>Comenzar
                </button>
                <button
                  v-if="start == false"
                  @click="initialize()"
                  class="btn-reset"
                  type="button"
                >
                  <i class="fas fa-undo"></i>Reiniciar
                </button>
                <div
                  v-if="btn_next == true"
                  class="content-btn-next justify-content-center"
                >
                  <!-- <div class="square-left"></div> -->
                  <div @click="next_lesson(id)" class="btn-next">
                    SIGUIENTE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="fixed-bottom">
      <div class="corner position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <p>Cargando Interface...</p>
  </div>
</template>


<script>
// import tiktok_embed from "@/assets/js/tiktok-embed.js"
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
export default {
  components: {
    NavMain
  },
  props: ["id_course", "id_leccion", "block_pos", "index_show"],
  data() {
    return {
      // course: null,
      game_title: "HANGMAN",
      game_rules:
        "Pulse los botones del ABC-dario y encuentre el concepto según la definición dada, si falla 12 veces perdera el juego",
      lesson: null,
      start: true,
      block_aleatory: null,
      concept: "",
      id_lesson: "",
      index_lesson: 0,
      complete_lessons: null,
      isPost: true,
      btn_next: false,
      url: process.env.VUE_APP_URL,
      // Hangman game data
      word_size: 0,
      strikes: 12,
      word: "HANGMAN",
      wordLetters: ["H", "A", "N", "G", "M", "A", "N"],
      wordDisplayLetters: ["H", "A", "N", "G", "M", "A", "N"],
      usedLetters: [],
      possibleLetters1: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
      possibleLetters2: ["J", "K", "L", "M", "N", "Ñ", "O", "P", "Q", "R", "S"],
      possibleLetters3: ["T", "U", "V", "W", "X", "Y", "Z"],
      initialized: false,
      // portrait
      is_potrait: false,
      is_landscape: false
    };
  },
  methods: {
    initialize() {
      //
      if (this.start === true) {
        this.start = false;
      }
      //
      this.initialized = true;
      this.strikes = 0;
      this.word = this.block_aleatory.concepto
        .toString()
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      this.word = this.word.replace(/\s+/g, "-");
      this.word_size = this.word.length;
      this.wordLetters = this.word.split("");
      this.wordDisplayLetters = Array(this.word.length);
      for (let i = 0; i < this.wordLetters.length; i++) {
        if (this.wordLetters[i] == "-") {
          // console.log(this.wordLetters[i])
          this.wordDisplayLetters[i] = "-";
        }
      }
      this.usedLetters = [];
    },
    tryLetter(letter) {
      if (this.usedLetters.includes(letter)) {
        return;
      }

      this.usedLetters.push(letter);
      let match = false;
      for (let i = 0; i < this.wordDisplayLetters.length; i++) {
        if (letter === this.wordLetters[i]) {
          this.wordDisplayLetters.splice(i, 1, letter);
          // console.log("display:", this.wordDisplayLetters);
          match = true;
        }
        // console.log("asdasd: ", this.wordDisplayLetters[5]);
      }
      if (!match) {
        this.strikes++;
        if (this.strikes > 11) {
          // alert("termino el juego")
          this.$swal({
            icon: "error",
            title: "Fallaste!",
            // text:
            // "Concluído el curso, resuelva el siguiente cuestionario para finalizar y aprobar el curso. Se le entregará un certificado despues de aprobar el cuestionario. Lea atentamente y conteste las preguntas, hay 2 tipos de preguntas, verdadero-falso y de selección multiple, tenga encuenta aveces existen mas de 2 respuestas correctas. Existe un límite de tiempo para resolver todo el cuestionario. Al aceptar este mensaje comenzaará a correr el tiempo. Suerte!",
            showCancelButton: true,
            confirmButtonText: "Reintentar",
            confirmButtonColor: "#8FD53B",
            cancelButtonColor: "#CB3234",
            focusCancel: true,
            cancelButtonText: "Volver a la lección"
          }).then(result => {
            if (result.isConfirmed) {
              this.initialize();
            } else {
              this.$router.back({});
            }
          });
        }
      } else {
        var word;
        word = this.wordLetters.toString();
        var word_try;
        word_try = this.wordDisplayLetters.toString();
        if (word == word_try) {
          // console.log("ganaste")
          this.btn_next = true;
          this.$swal({
            icon: "success",
            title: "Ganaste!",
            text:
              "Muy bien!!! Ahora pulsa el boton de siguiente para pasar a la siguiente lección!",
            confirmButtonText: "Super!",
            confirmButtonColor: "#8FD53B"
          }).then(result => {
            // if (result.isConfirmed) {
            // } else {
            //   this.$router.back({});
            // }
          });
        }
      }
    },
    getStrikethroughClass(letter) {
      if (this.usedLetters.includes(letter)) {
        return "diagonal-strike";
      }
      return null;
    },
    guion(letter) {
      if (letter == "-") {
        return "guion-class";
      }
      return null;
    },
    async next_lesson() {
      // Verifica si ya paso el curso
      var responseCompletar = await axios.get(
        this.url +
          "/api/cursos/" +
          this.id_course +
          "/lecciones-completas/" +
          localStorage.getItem("id"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.complete_lessons = responseCompletar.data;
      // console.log("completar: ",this.complete_lessons)
      this.complete_lessons.forEach(el => {
        if (el == this.id_leccion) {
          this.isPost = false;
        }
      });
      if (this.isPost == true) {
        // // Guarda la primera lección y su usuario para que sea visible en el curso.
        // console.log("postea")
        var responseCursoUsuario = await axios.post(
          this.url + "/api/cursos/" + this.id_course + "/completar-leccion",
          {
            leccion_id: this.id_leccion,
            usuario_id: localStorage.getItem("id")
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        );
        // console.log(responseCursoUsuario.data);
      }

      var index = Number.parseInt(this.index_show, 10);
      this.$router.push({
        name: "Course",
        params: { id: this.id_course, index_show: index + 1 }
      });
    },
    async handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        // if (screen.width <= 360 && screen.height <= 760) {
        //
        this.is_portrait = true;
        this.is_landscape = false
        // }
      } else if (orientation === "landscape-primary") {
        // landscape mode
        if (
          (screen.width <= 760 && screen.height <= 360) ||
          (screen.width <= 1080 && screen.height <= 810)
        ) {
          this.is_landscape = true;
        }
        this.is_portrait = false;
      }
    },
  },
  async mounted() {
    if (window.innerHeight > window.innerWidth) {
      //
      this.is_portrait = true;
      this.is_landscape = false
    } else {
      this.is_portrait = false;
      if (
        (screen.width <= 760 && screen.height <= 360) ||
        (screen.width <= 1080 && screen.height <= 810)
      ) {
        this.is_landscape = true;
      }
    }
    // console.log("land:", this.is_landscape);
    window.addEventListener("orientationchange", this.handleOrientationChange);
    var responseLesson = await axios.get(
      this.url + "/api/lecciones/" + this.id_leccion,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    this.lesson = responseLesson.data;
    // console.log("leccion: ", this.lesson);
    var blocks = this.lesson.bloques;
    // var amount_blocks = this.lesson.bloques.length;
    // console.log(amount_blocks)
    // this.block_aleatory = blocks[Math.floor(Math.random() * blocks.length)];
    // this.concept = this.block_aleatory.concepto.toString().toUpperCase()
    // console.log(this.block_aleatory.concepto);
    // this.word = this.block_aleatory.concepto
    // console.log("block_pos: ",this.block_pos)
    this.block_aleatory = blocks[this.block_pos];

    // console.log("alea: ",this.block_aleatory)
  }
};
</script>


<style scoped>
hr {
  color: #7ab929;
  height: 5px;
  font-weight: bold;
}
.menu-line {
  color: #ffffff;
  height: 5px;
  width: auto;
  padding-left: 20px;
}
.main {
  position: relative;
  margin-bottom: 30vmin;
}
.main-game {
  /* margin-top: 12rem; */
  margin-top: 20vmin;
  /* margin-left: 10rem; */
  margin-left: 17vmin;
}
/* CONTENIDO DERECHO */
.side-bar {
  /* margin-top: 12rem; */
  margin-top: 16vmin;
  padding-bottom: 2rem;
  /* background: #1D2945; */
  background-color: rgba(29, 41, 69, 0.99);
}
.title-menu {
  color: #7ab929;
  /* font-size: 28px; */
  font-size: 3vmin;
  font-weight: bold;
  text-align: center;
  font-family: "Fredoka One", cursive;
  /* padding-top: 10px; */
  padding-top: 3.5vmin;
  /* margin-bottom: 8px; */
  /* padding-left: 20px; */
}
.subtitle {
  color: #fff;
  padding-left: 20px;
  font-family: "Fredoka One", cursive;
  /* font-size: 25px; */
  font-size: 3.5vmin;
}
.content-side-bar {
  /* margin-top: 5rem; */
  margin-bottom: 3rem;
}
.text-side-bar {
  color: #fff;
  /* font-size: 25px; */
  font-size: 3.3vmin;
  margin: auto;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  padding-right: 2vmin;
  padding-left: 2vmin;
  font-family: "Poiret One", cursive !important;
}
.text-definition {
  color: #1d2945;
  /* font-size: 1.3rem; */
  font-size: 4vmin;
  /* margin: auto; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  font-family: "Poiret One", cursive !important;
}
.btn-reset {
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* padding-left: 40px; */
  /* padding-right: 40px; */
  padding: 1vmin 2vmin 1vmin 2vmin;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.fa-undo,
.fa-play {
  /* font-size: 20px; */
  font-size: 2.3vmin;
  margin-right: 15px;
  align-items: center;
  /* margin-top: 5px; */
  margin-top: 1.1vmin;
}
.btn-next {
  background-color: #fff;
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* padding-left: 40px; */
  /* padding-right: 40px; */
  padding: 0.5vmin 2vmin 0.5vmin 2vmin;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 2vmin solid #7ab929;
}
.content-btn-next {
  display: flex;
  cursor: pointer;
  margin-bottom: 3rem;
}
.square-left {
  background-color: #7ab929;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* CONTENIDO IZQUIERDO */
.definition-title {
  /* margin-top: 2rem; */
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 2rem; */
  font-size: 3.5vmin;
}
/* POSICION Y FONDO */
.fixed-bottom {
  z-index: 1;
}
.pos {
  z-index: 2;
  display: flex;
}
.corner {
  width: 70%;
}
.green {
  width: 100%;
  height: 100%;
}
/* GAME */
.doll {
  /* margin-top: 15rem; */
  margin-top: 20vmin;
}
.doll-portrait {
  /* display: flex; */
  /* justify-content: start; */
  /* position: absolute; */
  /* left: 0; */
  text-align: start !important;
  /* margin-right: 60rem !important; */
}
.content-letter {
  /* margin-top: 10rem; */
  margin-top: 17.5vmin;
  /* margin-bottom: 20px; */
  margin-bottom: 15vmin;
  text-align: center;
  /* margin: auto; */
}
.letter {
  display: inline-block;
  border-bottom: 1px solid;
  margin: 0px 3px 0px 3px;
  /* font-size: 35px; */
  font-size: 3.7vmin;
  /* min-width: 30px; */
  min-width: 3vmin;
  vertical-align: bottom;
}
.possibleLetter {
  display: inline-block;
  margin: 10px 3px 0px 3px;
  /* font-size: 35px; */
  font-size: 4vmin;
  /* min-width: 30px; */
  min-width: 3.3vmin;
  cursor: pointer;
}

.diagonal-strike {
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
  color: #7ab929;
}

.content-keyword {
  /* padding-top: 1rem; */
  margin: auto;
  display: flex;
  justify-content: center;
}

.guion-class {
  border: 0px solid white !important;
  opacity: 0;
}


.btn-new-game {
  /* padding-top: 1rem; */
  /* margin-top: 1rem; */
  margin: auto;
  display: flex;
  justify-content: center;
}
</style>