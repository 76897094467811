<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <div class="logo">
        <router-link :to="{ name: 'Home' }" class="navbar-brand" href="#">
          <img
            @click="homeActive"
            class="logo-img img-fluid"
            src="../assets/img/LOGO-Edu-tiktok.png"
            alt=""
            
          />
          <!-- <img
            @click="homeActive"
            class="logo-img img-fluid"
            src="../assets/img/logos/ue-logo.png"
            alt=""
            height="120"
          /> -->
        </router-link>
      </div>
      <!-- <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="justify-content-end" id="navbarNavDropdown">
        <ul
          class="navbar-nav flex-row d-flex position-absolute end-0 top-0 py-2 align-items-center"
        >
          <li>
            <div class="search d-flex">
              <input
                type="search"
                placeholder="¿Qué quieres aprender hoy?"
                class="form-control input-search"
                @change="onChange"
                @keyup="onChange"
                v-model="search"
              />
              <div
                class="icon-search d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-search"></i>
              </div>
            </div>
          </li>
          <li
            class="nav-item"
            @mouseover="courses = true"
            @mouseleave="courses = false"
          >
            <img
              src="../assets/img/ICONO-mis-cursos.png"
              alt=""
              class="icon-nav"
              @click="display_courses()"
            />
            <transition name="fade">
              <ul class="menu-profile" v-if="courses">
                <p class="submenu-title">Cursos</p>
                <router-link :to="{ name: 'MyCourses' }">
                  <li class="submenu">Mis cursos</li>
                </router-link>
                <router-link :to="{ name: 'MyCoursesTeacher' }">
                  <li
                    v-if="
                      user.role == 'Docente' || user.role == 'Administrador'
                    "
                    class="submenu"
                  >
                    Mis cursos: Docente
                  </li>
                </router-link>
              </ul>
            </transition>
          </li>
          <!-- <li
            class="nav-item"
            @mouseover="certificates = true"
            @mouseleave="certificates = false"
          >
             <img
              src="../assets/img/ICONO-mis-certificados.png"
              alt=""
              class="icon-nav i-cert"
            />
            <transition name="fade">
              <ul class="menu-profile" v-if="certificates" @click="certificates = false">
                <p class="submenu-title">
                  CERTIFICADOS
                </p>
                <router-link :to="{ name: 'Certificates' }">
                  <li class="submenu">Mis Certificados</li>
                </router-link>
                <li class="submenu">Material descargable</li>
              </ul>
            </transition>
          </li> -->
          <li
            class="nav-item"
            @mouseover="profile = true"
            @mouseleave="profile = false"
          >
            <div
              @click="display_profile()"
              class="
                rounded-circle
                profile
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <span class="letter" v-if="user">{{ user.letter }}</span>
            </div>
            <transition class="menu-profile2" name="fade">
              <ul class="" v-if="profile">
                <p class="submenu-title">Perfil</p>
                <router-link :to="{ name: 'MyProfile' }">
                  <li class="submenu">Mi Perfil</li>
                </router-link>
                <!-- <li class="submenu">Editar perfil</li> -->
                <router-link :to="{ name: 'AdminUsers' }"
                  ><li v-if="user.role == 'Administrador'" class="submenu">
                    Administrar usuarios
                  </li></router-link
                >
                <router-link :to="{ name: 'AdminCourses' }">
                  <li v-if="user.role == 'Administrador'" class="submenu">
                    Administrar cursos
                  </li>
                </router-link>
                <!-- <li v-if="user.role == 'Administrador'" class="submenu">
                  Administrar lecciones
                </li> -->
                <li class="submenu" @click="logout">Cerrar sesión</li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  // props: ["user"],
  data() {
    return {
      profile: false,
      certificates: false,
      courses: false,
      search: "",
      user: {
        name: "",
        last_name: "",
        email: "",
        age: "",
        pass: "",
        c_pass: "",
        role: "",
        letter: ""
      },
      url: process.env.VUE_APP_URL
    };
  },
  mounted() {
    // console.log("courses: ", this.courses);
  },
  async created() {
    const response = await axios
      .get(this.url + "/api/usuarios/" + localStorage.getItem("id"), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      .then(response => {
        // localStorage.setItem('token', response.data)
        // console.log(response)
        // this.user = response.data
        this.user.name = response.data.nombre;
        this.user.last_name = response.data.apellido;
        this.user.email = response.data.correo;
        this.user.age = response.data.edad;
        this.user.pass = response.data.contrasena;
        this.user.c_pass = response.data.confirmar_contrasena;
        this.user.role = response.data.rol;
        this.user.letter = response.data.nombre.charAt(0);
        // console.log(this.letter)
      })
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    onChange() {
      this.$emit("search", this.search);
    },
    logout() {
      localStorage.clear();
      localStorage.removeItem("bloques");
      this.$router.push("/");
    },
    display_courses() {
      if (this.courses === false) {
        this.courses = true;
        this.profile = false
      } else {
        this.courses = false;
      }
    },
    display_profile() {
      if (this.profile === false) {
        this.profile = true;
        this.courses = false
      } else {
        this.profile = false;
      }
    },
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.container-fluid {
  max-height: 100px;
}
.logo {
  max-width: 20vmin;
  /* float: left; */
  /* display: flex; */
}
.logo-img {
  /* max-width: 14vmin; */
  width: 100%;
  display: inline-block;
  /* padding: 0.5rem 1rem; */
}
.bg-light {
  /* background-color: transparent !important; */
  background: white;
}
.image {
  margin-top: -20px;
  /* max-height: 100px; */
}
.search {
  width: 45vmin;
  /* margin-right: 25px; */
  /* padding-right: 100px; */
}
.input-search {
  /* width: auto; */
  /* height: 3.5rem; */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  font-family: "Poiret One", cursive;
  background: #cbcdd4;
  color: #fff;
  font-size: 2.3vmin;
  font-weight: bold;
}
.input-search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}
.input-search:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.input-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.input-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
.icon-search {
  width: 3.5rem;
  /* height: 3.5rem; */
  margin-left: -2px;
  background: #7ab929 !important;
  color: #fff;
  font-size: 2.3vmin;
}

.nav-item {
  max-width: 100px;
  max-height: 100px;
}
.icon-nav {
  width: 10vmin;
  height: 10vmin;
  margin-left: 5px;
  /* margin-right: 15px; */
  cursor: pointer;
}
.i-cert {
  padding: 5px;
}
.profile {
  background: #7ab929;
  width: 8vmin;
  height: 8vmin;
  font-family: "Fredoka One", cursive;
  /* font-size: 3rem; */
  color: #fff;
  /* margin: 8px; */
  cursor: pointer;
  /* margin-bottom: 18px; */
  margin-right: 15px;
  /* margin-left: 30px; */
}
.letter {
  font-size: 6vmin;
}
/* SUBMENU */
/* ------- */
.menu-profile {
  /* padding-right: 50px; */
  width: 24vmin;
  text-align: left;
  /* margin-top: 50px; */
  background: #fff;
  font-family: "Poiret One", cursive !important;
  font-size: 2.4vmin;
  /* transform: translate(-80%, 0) !important; */
  transform: translate(-10vmin, 0) !important;
  box-shadow: 0px 10px 10px black;
  position: absolute;
  /* margin-top: 5rem; */
}
.menu-profile2 {
  /* padding-right: 50px; */
  width: 25vmin;
  text-align: left;
  /* margin-top: 50px; */
  background: #fff;
  font-family: "Poiret One", cursive !important;
  font-size: 2.4vmin;
  /* transform: translate(-40%, 10%) !important; */
  transform: translate(-17vmin, 1vmin) !important;
  box-shadow: 0px 10px 10px black;
  position: absolute;
  /* margin-top: 5rem; */
}
.submenu {
  color: #1d2945;
  cursor: pointer;
}
.submenu:hover {
  color: #7ab929;
  font-weight: bold;
}
.submenu-title {
  font-family: "Fredoka One", cursive;
  font-size: 2.4vmin;
  color: #7ab929;
  /* font-weight: bold; */
  /* text-align: center; */
}
</style>