<template>
  <footer class="footer-class">
    <div class="container-fluid">
      <div class="main-footer">
        <div class="text-footer">Auspiciado por:</div>
        <div class="logo">
          <img
            class="logo-img1 img-fluid"
            src="../assets/img/logos/haurralde/HAURRALDE-LOGO.jpeg"
          />
          <img
            class="logo-img2 img-fluid"
            src="../assets/img/logos/haurralde/E-LOGO.jpeg"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
/* footer {
    position:absolute;
   bottom:0;
   width:100%;
   height:60px;   
   background:#6cf;
   z-index: 4;
} */
.main-footer{
  display: flex;
  align-items: center;
  /* float: right;   */
}
.logo {
  display: flex;
  /* max-width: 58vmin; */
  /* width: auto; */
  max-height: 9vmin;
  padding: 5px;
  /* float: right; */
}
.logo-img1 {
  /* max-width: 17vmin; */
  width: 100%;
  max-width: 25vmin;
  /* height: 2.3vmin; */
  display: inline-block;
  margin-right: 2vmin;
  border-radius: 10px;
}
.logo-img2 {
  /* max-width: 17vmin; */
  width: 100%;
  max-width: 34vmin;
  /* height: 2.3vmin; */
  display: inline-block;
  margin-right: 2vmin;
  border-radius: 10px;
}
.text-footer {
  color: #fff;
  font-size: 2.5vmin;
  font-family: "Fredoka One", cursive;
  display: inline-block;
  margin-right: 2vmin;
}
</style>