<template>
  <NavMain />
  <div class="main" v-if="lesson">
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="pos">
            <div class="col-7">
              <div class="main-game">
                <!-- Game -->
                <!-- <p class="victoryState" v-if="victory">
                  <button @click="reset()" class="btn btn-primary">
                    Refresh ?
                  </button>
                </p> -->
                <div id="victory" v-if="victory"></div>

                <div v-if="initialized">
                  <div class="timer">TIEMPO: {{ timeleft }}</div>
                  <div
                    class="card-size"
                    v-for="(card, index) in cards"
                    :key="index"
                    :class="[
                      {
                        down: card.down && !card.matched,
                        up: !card.down,
                        matched: card.matched
                      },
                      'card'
                    ]"
                    @click="handleClick(card)"
                  >
                    <!-- <i class="tag-i" :class="'fas ' + card.icon"></i> -->
                    <p class="tag-p">{{ card.icon }}</p>
                  </div>
                </div>
                <!-- <div >
                  Pulse comenzar para iniciar
                </div> -->
                <!--  -->
              </div>
            </div>
            <div class="col-4 position-fixed end-0">
              <div class="side-bar">
                <p class="title-menu">{{ game_title }}</p>
                <!-- <p class="line"></p> -->

                <div class="content-side-bar">
                  <!-- <p class="title-menu">Cuestionario</p> -->
                  <!-- <p class="line"></p> -->
                  <div class="justify-content-center">
                    <div class="subtitle">Reglas:</div>
                    <hr class="menu-line" />
                    <p class="text-side-bar">
                      {{ game_rules1 }}
                    </p>
                    <p class="text-side-bar">
                      {{ game_rules2 }}
                    </p>
                    <p class="text-side-bar">
                      {{ game_rules3 }}
                    </p>
                    <p class="text-side-bar">
                      {{ game_rules4 }}
                    </p>
                    <div class="subtitle">Conceptos:</div>
                    <div
                      class="text-side-bar"
                      v-for="con in concepts"
                      :key="con"
                    >
                      <p>
                        <span class="viñeta">{{ con.index }}. </span>
                        <span class="data-rules">{{ con.concept }}</span>
                      </p>
                    </div>
                    <div class="subtitle">Definiciones:</div>
                    <div
                      class="text-side-bar"
                      v-for="def in definitions"
                      :key="def"
                    >
                      <p>
                        <span class="viñeta">{{ def.index }}) </span>
                        <span class="data-rules">{{ def.definition }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  v-if="start == true"
                  @click="initialize()"
                  class="btn-reset"
                  type="button"
                >
                  <i class="fas fa-play"></i>Comenzar
                </button>
                <button
                  v-if="start == false"
                  @click="reset()"
                  class="btn-reset"
                  type="button"
                >
                  <i class="fas fa-undo"></i>Reiniciar
                </button>
                <div
                  v-if="btn_next == true"
                  class="content-btn-next justify-content-center"
                >
                  <!-- <div class="square-left"></div> -->
                  <div @click="next_lesson(id)" class="btn-next">
                    SIGUIENTE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="fixed-bottom">
      <div class="corner position-absolute bottom-0 start-0">
        <img src="../../assets/img/ESQUINA-VERDE.png" alt="" class="green" />
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <p>Cargando Interface...</p>
  </div>
</template>


<script>
import NavMain from "@/components/NavMain.vue";
import axios from "axios";
// GAME
// const icons = [
// "fa-kiwi-bird",
// "fa-chess",
// "fa-frog",
// "fa-camera-retro",
// "fa-plug",
// "fa-anchor",
// "fa-birthday-cake",
// "fa-cube",
// "fa-dice",
// "fa-bug",
// "fa-cut",
// "fa-gem"
// ];

// var blocks_cards = [];
// const url_string = process.env.VUE_APP_URL;

// if (localStorage.getItem("token") != undefined) {
//   const datos = fetch(
//     url_string + "/api/lecciones/" + location.pathname.substr(12),
//     {
//       method: "get",
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token")
//       }
//     }
//   )
//     .then(response => response.json())
//     .then(data => {
//       blocks_cards = data.bloques;
//       localStorage.setItem("bloques", JSON.stringify(blocks_cards));
//       // return bloques;
//     });
// }
// if (localStorage.getItem("bloques")) {
//   blocks_cards = JSON.parse(localStorage.getItem("bloques"));
// }
// // localStorage.removeItem("bloques")
// const icons = [];
// blocks_cards.forEach(el => {
//   var desc = el.definicion.substring(0, 15) + "...";
//   var cont = el.contenido.substring(0, 15) + "...";
//   console.log("desc: ", desc);
//   console.log("cont: ", cont);
//   icons.push(el.titulo, el.concepto, desc, cont);
// });
// ///////////////////////////////////////
// Duplicate elements of an array
// const duplicate = arr => {
//   return arr.concat(arr).sort();
// };

// Check if two cards are a match
// const checkMatch = icons => {
//   if (icons[0] === icons[1]) {
//     console.log("it's a match");
//     return true;
//   }
// };
import _ from "lodash";
//
export default {
  components: {
    NavMain
  },
  props: ["id_course", "id_leccion", "index_show"],
  data() {
    return {
      // course: null,
      game_title: "CARD MEMORY",
      game_rules1: "Encuentre los pares según lo aprendido en la lección.",
      game_rules2: "Se muestran a continuación CONCEPTOS Y DEFINICIONES:",
      game_rules3:
        "Cada CONCEPTO pertenece a una DEFINICIÓN, primero identifica los pares de número y letra.",
      game_rules4:
        "Ejemplo: 1. Perro ---> C) Es un animal que ladra. Luego de identificar los pares, pulse el boton de Comenzar para encontrar los pares en las cartas del tablero. Tiene 1 minuto para conseguirlo.",
      lesson: null,
      complete_lessons: null,
      isPost: true,
      start: true,
      // block_aleatory: null,
      // concept: "",
      id_lesson: "",
      btn_next: false,
      url: process.env.VUE_APP_URL,
      // Card memory data
      // cards: _.range(0, icons.length * 2),
      cards: [],
      runing: false,
      initialized: false,
      timeleft: 60,
      timeHolder: null,
      secciones: [],
      concepts: [],
      definitions: [],
      abc: "abcdefghijk"
    };
  },
  methods: {
    initialize() {
      if (this.start === true) {
        this.start = false;
      }
      this.initialized = true;
      //
      if (this.timeHolder != null) {
        clearInterval(this.timeHolder);
      }
      this.timeHolder = setInterval(() => {
        this.timeleft -= 1;
        if (this.timeleft <= 0) {
          clearInterval(this.timeHolder);
          //
          this.$swal({
            icon: "error",
            title: "Fallaste!",
            text: "Se termino el tiempo!",
            // "Concluído el curso, resuelva el siguiente cuestionario para finalizar y aprobar el curso. Se le entregará un certificado despues de aprobar el cuestionario. Lea atentamente y conteste las preguntas, hay 2 tipos de preguntas, verdadero-falso y de selección multiple, tenga encuenta aveces existen mas de 2 respuestas correctas. Existe un límite de tiempo para resolver todo el cuestionario. Al aceptar este mensaje comenzaará a correr el tiempo. Suerte!",
            showCancelButton: true,
            confirmButtonText: "Reintentar",
            confirmButtonColor: "#8FD53B",
            cancelButtonColor: "#CB3234",
            focusCancel: true,
            cancelButtonText: "Volver a la lección"
          }).then(result => {
            if (result.isConfirmed) {
              this.reset();
            } else {
              this.$router.back({});
            }
          });
        }
      }, 1000);
    },
    reset() {
      if (this.timeHolder != null) {
        clearInterval(this.timeHolder);
      }
      this.$router.replace({
        params: {
          id_course: this.id_course,
          id_leccion: this.id_leccion,
          index_show: this.index_show
        },
        path: "/processingCard/:id_course/:id_leccion/:index_show",
        name: "ProcessingCard"
      });
    },
    // GAME
    // Create cards array based on icons, shuffle them
    cardsShuffle() {
      //
      // this.secciones = _.range(0, this.lesson.bloques.length * 2);
      this.cards = _.range(0, this.lesson.bloques.length * 2);
      // const abc = "abcdefghijklmn";
      // for (let i = 0; i < this.lesson.bloques.length; i++) {
      //   this.secciones[i] = i + 1;
      //   this.secciones[i + this.lesson.bloques.length] = this.abc.charAt(i);
      // }
      //
      // prep objects
      this.cards.forEach((card, index) => {
        this.cards[index] = {
          index: 0,
          icon: "",
          down: true,
          matched: false
        };
      });
      var j = 0;
      //  console.log("cards: ",this.cards)
      for (let i = 0; i < this.cards.length; i++) {
        // console.log(this.secciones[i])
        this.cards[i].index = this.secciones[j].index;
        if (i > 2) {
          this.cards[i].icon = this.secciones[j].letter;
        } else {
          this.cards[i].icon = this.secciones[j].number;
        }
        if (j < 2) {
          j++;
        } else {
          j = 0;
        }
      }
      // console.log("cards: ", this.cards);
      // // input every icon two times
      // icons.forEach((icon, index) => {
      //   this.cards[index].icon = icon;
      //   // no necesitaria push 2 veces
      //   this.cards[index + icons.length].icon = icon;
      // });
      this.cards = _.shuffle(this.cards);
    },
    handleClick(cardClicked) {
      if (!this.runing) {
        // turn card up
        if (!cardClicked.matched && this.cardCount.cardsUp < 2) {
          cardClicked.down = false;
        }
        // when two cards are up, check if they match or turn them down
        if (this.cardCount.cardsUp === 2) {
          this.runing = true;
          setTimeout(() => {
            let match = this.checkMatch(this.cardCount.icons);
            this.cards.forEach(card => {
              if (match && !card.down && !card.matched) {
                card.matched = true;
              } else {
                card.down = true;
              }
            });
            this.runing = false;
          }, 900);
        }
      }
    },
    //
    async next_lesson() {
      // Verifica si ya paso el curso
      var responseCompletar = await axios.get(
        this.url +
          "/api/cursos/" +
          this.id_course +
          "/lecciones-completas/" +
          localStorage.getItem("id"),
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      );
      this.complete_lessons = responseCompletar.data;
      // console.log("completar: ", this.complete_lessons);
      this.complete_lessons.forEach(el => {
        if (el == this.id_leccion) {
          this.isPost = false;
        }
      });
      if (this.isPost == true) {
        // // Guarda la primera lección y su usuario para que sea visible en el curso.
        // console.log("postea");
        var responseCursoUsuario = await axios.post(
          this.url + "/api/cursos/" + this.id_course + "/completar-leccion",
          {
            leccion_id: this.id_leccion,
            usuario_id: localStorage.getItem("id")
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        );
        // console.log(responseCursoUsuario.data);
      }
      var index = Number.parseInt(this.index_show, 10)
      this.$router.push({
        name: "Course",
        params: {
          id: this.id_course,
          index_show: index + 1
        }
      });
    },
    checkMatch(icons_index) {
      if (icons_index[0] === icons_index[1]) {
        // console.log("it's a match");
        return true;
      }
    }
  },
  async mounted() {
    // GAME
    var responseLesson = await axios.get(
      this.url + "/api/lecciones/" + this.id_leccion,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      }
    );
    var a_c = "abc";
    var one_three = [1, 2, 3];
    one_three = _.shuffle(one_three);
    a_c = _.shuffle(a_c);
    this.lesson = responseLesson.data;
    for (let i = 0; i < this.lesson.bloques.length; i++) {
      this.secciones.push({
        index: i,
        number: one_three[i],
        concept: this.lesson.bloques[i].concepto,
        letter: a_c[i],
        definition: this.lesson.bloques[i].definicion
      });
    }
    // show_blocks
    this.secciones.forEach(el => {
      this.concepts.push({
        index: el.number,
        concept: el.concept
      });
    });
    this.secciones.forEach(el => {
      this.definitions.push({
        index: el.letter,
        definition: el.definition
      });
    });

    // console.log("definitions: ", this.definitions)
    // console.log(this.secciones);
    this.concepts = _.sortBy(this.concepts, ["index"], ["asc"]);
    // console.log("concecpts: ", this.concepts);
    this.definitions = _.sortBy(this.definitions, ["index"], ["asc"]);
    this.cardsShuffle();
    //
  },
  watch: {
    $route(to, from) {
      if (this.timeHolder != null) {
        clearInterval(this.timeHolder);
        // localStorage.removeItem("bloques")
      }
    }
  },
  computed: {
    // checkMatch: function(icons) {
    //   if (icons[0] === icons[1]) {
    //     console.log("it's a match");
    //     return true;
    //   }
    // },
    // make a count of cards up and cards matched, keep icons of cards to check in array
    cardCount: function() {
      let cardUpCount = 0;
      let cardMatchedCount = 0;
      let icons = [];
      this.cards.forEach(card => {
        if (!card.down && !card.matched) {
          cardUpCount++;
          //  push index
          // icons.push(card.icon);
          icons.push(card.index);
        }
        if (card.matched) {
          cardMatchedCount++;
        }
      });
      return {
        cardsUp: cardUpCount,
        cardsMatched: cardMatchedCount,
        icons: icons
      };
    },
    // update victory state
    victory: function() {
      if (this.cardCount.cardsMatched === this.cards.length) {
        clearInterval(this.timeHolder);
        this.btn_next = true;
        this.$swal({
          icon: "success",
          title: "Ganaste!",
          text:
            "Muy bien!!! Ahora pulsa el boton de siguiente para pasar a la siguiente lección!",
          confirmButtonText: "Super!",
          confirmButtonColor: "#8FD53B",
          target: document.getElementById("victory")
        }).then(result => {
          // if (result.isConfirmed) {
          // } else {
          //   this.$router.back({});
          // }
        });
        return true;
      }
    }
  }
};
</script>


<style scoped>
hr {
  color: #7ab929;
  height: 5px;
  font-weight: bold;
}
.menu-line {
  color: #ffffff;
  height: 5px;
  width: auto;
  padding-left: 20px;
}
.main {
  position: relative;
  margin-bottom: 100vmax;
}
.main-game {
  /* margin-top: 12rem; */
  /* margin-left: 10rem; */
  margin-top: 20vmin;
  margin-left: 17vmin;
}
/* CONTENIDO DERECHO */
.side-bar {
  /* margin-top: 12rem; */
  margin-top: 16vmin;
  padding-bottom: 2rem;
  /* background: #1D2945; */
  background-color: rgba(29, 41, 69, 0.99);
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
}
.title-menu {
  color: #7ab929;
  /* font-size: 28px; */
  font-size: 3vmin;
  font-weight: bold;
  text-align: center;
  font-family: "Fredoka One", cursive;
  /* padding-top: 10px; */
  padding-top: 3.5vmin;
  /* margin-bottom: 8px; */
  /* padding-left: 20px; */
}
.subtitle {
  color: #fff;
  padding-left: 20px;
  font-family: "Fredoka One", cursive;
  /* font-size: 25px; */
  font-size: 3.5vmin;
}
.content-side-bar {
  /* margin-top: 5rem; */
  margin-bottom: 3rem;
}
.text-side-bar {
  color: #fff;
  /* font-size: 25px; */
  font-size: 3.3vmin;
  margin: auto;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  padding-right: 2vmin;
  padding-left: 2vmin;
  font-family: "Poiret One", cursive !important;
}
.text-definition {
  color: #1d2945;
  /* font-size: 2.2rem; */
  font-size: 4vmin;
  /* margin: auto; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  font-family: "Poiret One", cursive !important;
}
.btn-reset {
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  margin-top: 20px;
  margin-bottom: 4rem;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* padding-left: 40px; */
  /* padding-right: 40px; */
  padding: 1vmin 2vmin 1vmin 2vmin; 
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.fa-undo,
.fa-play {
  /* font-size: 20px; */
  font-size: 2.3vmin;
  margin-right: 15px;
  align-items: center;
  /* margin-top: 5px; */
  margin-top: 1.1vmin;
}
.btn-next {
  background-color: #fff;
  color: #7ab929;
  font-family: "Fredoka One", cursive;
  /* font-size: 1.5rem; */
  font-size: 3vmin;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* padding-left: 40px; */
  /* padding-right: 40px; */
  padding: 0.5vmin 2vmin 0.5vmin 2vmin;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 2vmin solid #7ab929;
}
.content-btn-next {
  display: flex;
  cursor: pointer;
  margin-bottom: 3rem;
}
.square-left {
  background-color: #7ab929;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* CONTENIDO IZQUIERDO */
.definition-title {
  /* margin-top: 2rem; */
  color: #1d2945;
  font-family: "Fredoka One", cursive;
  /* font-size: 4rem; */
  font-size: 3.5vmin;
}
/* POSICION Y FONDO */
.fixed-bottom {
  z-index: 1;
}
.pos {
  z-index: 2;
  display: flex;
}
.corner {
  width: 70%;
}
.green {
  width: 100%;
  height: 100%;
}
/* GAME */
.card-size {
  /* width: 14.4rem; */
  width: 25vmin;
  /* height: 13.4rem; */
  height: 25vmin;
  /* justify-content: center; */
  /* display: flex; */
  /* align-items: center; */
  margin-bottom: 15px;
  margin-right: 25px;
  /* margin: auto; */
}
/* .tag-i .fas .tag-p  .obscure{
  font-size: 40px;
  color: #333;
} */
/* .tag-i {
  font-size: 40px;
  color: #333;
} */
.tag-p {
  /* font-size: 5rem; */
  font-size: 15.5vmin;
  text-transform: uppercase;
  /* color: #333; */
  color: #1d2945;
  /* opacity: 0; */
}
.victoryState {
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card {
  background: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 5px 5px 0 0 #333;
  cursor: pointer;
  animation: flipUp 0.5s forwards;
}
.card .tag-p {
  /* color: green; */
  /* background-color: red; */
  /* font-size: 20rem; */
  opacity: 1;
  transition: opacity 0.5s;
}
.card.down {
  animation: flipDown 0.5s forwards;
}
.card.down .tag-p {
  opacity: 0;
}

.card.matched {
  animation: matching 0.3s forwards;
}
@keyframes flipDown {
  0% {
    background: #fff;
    transform: rotateY(0deg);
    box-shadow: 5px 5px 0 0 #333;
  }
  100% {
    background: #1d2945;
    transform: rotateY(180deg);
    box-shadow: -8px 8px 0 0 #cbcdd4;
    /* box-shadow: #CBCDD4; */
  }
}
@keyframes flipUp {
  0% {
    background: #fff;
    transform: rotateY(180deg);
    box-shadow: -5px 5px 0 0 #cbcdd4;
  }
  100% {
    background: #fff;
    transform: rotateY(0deg);
    box-shadow: 8px 8px 0 0 #cbcdd4;
  }
}
@keyframes matching {
  0% {
    background: #fff;
  }
  100% {
    background: #7ab929;
  }
}
/*  */
.btn-new-game {
  /* padding-top: 1rem; */
  /* margin-top: 1rem; */
  margin: auto;
  display: flex;
  justify-content: center;
  /* padding-bottom: 1rem; */
}
.viñeta {
  color: #7ab929;
  font-weight: bold;
  font-family: "Frodoka One", cursive;
}
.data-rules::first-letter {
  text-transform: uppercase !important;
}
.timer {
  color: #7ab929;
  font-weight: bold;
  font-family: "Fredoka One", cursive;
  /* font-size: 2rem; */
  font-size: 3.5vmin;
  margin-bottom: 20px;
}
</style>